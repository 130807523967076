body {
  font-family: 'Roboto', sans-serif;
  background-color: #f0f4f8;
  color: #333;
  line-height: 1.6;
}

.App {
  max-width: 1000px;
  margin: 40px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #2c3e50;
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.2em;
  font-weight: 300;
}

h2 {
  color: #34495e;
  margin-bottom: 15px;
  font-size: 1.5em;
  font-weight: 400;
}

.matrix-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;
  margin-bottom: 30px;
}

.matrix-wrapper {
  background-color: #f8fafc;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.matrix-wrapper:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.operation {
  font-size: 24px;
  font-weight: bold;
  color: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #e8f4fd;
  border-radius: 50%;
  margin: 0 10px;
}

.matrix-cell,
.size-input,
.result-input {
  width: 40px;
  height: 30px;
  margin: 3px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #ffffff;
  transition: all 0.3s ease;
  -moz-appearance: textfield;
}

.matrix-cell:focus,
.size-input:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.matrix-cell::-webkit-inner-spin-button,
.matrix-cell::-webkit-outer-spin-button,
.size-input::-webkit-inner-spin-button,
.size-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.result-input {
  background-color: #e8f4fd;
  border-color: #3498db;
  font-weight: bold;
}

.size-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 8px;
  background-color: #f0f4f8;
  border-radius: 4px;
}

.size-controls label {
  margin-right: 5px;
  font-weight: 500;
  color: #34495e;
  font-size: 0.9em;
}

.size-controls span {
  margin: 0 5px;
  color: #7f8c8d;
}

button {
  display: block;
  margin: 20px auto;
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 25px;
  font-size: 16px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(52, 152, 219, 0.2);
}

button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(52, 152, 219, 0.3);
}

.result-container {
  text-align: center;
  margin-top: 30px;
}

.result-wrapper {
  display: inline-block;
  margin-top: 15px;
}

@media (max-width: 768px) {
  .matrix-container {
    flex-direction: column;
    align-items: center;
  }

  .operation {
    margin: 10px 0;
  }
}

.top-menu {
  background-color: #2c3e50;
  padding: 1rem;
  color: white;
}

.top-menu h1 {
  margin: 0;
  font-size: 1.5rem;
}

.top-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0 0 0;
}

.top-menu li {
  display: inline;
  margin-right: 1rem;
}

.top-menu a {
  color: white;
  text-decoration: none;
}

.top-menu a:hover {
  text-decoration: underline;
}

.content {
  padding: 20px;
  margin-top: 60px;
  /* Adjust this value based on your top menu height */
}

/* ... (keep your existing styles) ... */

.lu-decomposition {
  max-width: 1000px;
  margin: 40px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.lu-decomposition h3 {
  text-align: center;
  margin-bottom: 10px;
  color: #34495e;
}