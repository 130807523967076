.eigenvalue-calculator {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.eigenvalue-calculator h2 {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.eigenvalue-calculator .matrix-input {
    margin-bottom: 20px;
}

.eigenvalue-calculator button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.eigenvalue-calculator button:hover {
    background-color: #0056b3;
}

.eigenvalue-calculator .result {
    margin-top: 20px;
    padding: 15px;
    background-color: #e9ecef;
    border-radius: 4px;
}

.eigenvalue-calculator .result h3 {
    color: #333;
    margin-bottom: 10px;
}

.eigenvalue-calculator pre {
    background-color: #f1f3f5;
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
}

@media (max-width: 600px) {
    .eigenvalue-calculator {
        padding: 15px;
    }
}

.eigenvalue-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-family: Arial, sans-serif;
    table-layout: fixed;
    /* Ensure the table respects the width of its cells */
}

.eigenvalue-table th,
.eigenvalue-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.eigenvalue-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.eigenvalue-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.eigenvalue-table tr:hover {
    background-color: #f5f5f5;
}

.eigenvalue-table td.eigenvalue-result {
    width: 300px;
    /* Adjust this value as needed */
    font-family: monospace;
    /* Use a monospace font for better alignment */
    white-space: nowrap;
    /* Prevent wrapping */
    overflow: hidden;
    /* Hide overflow */
    text-overflow: ellipsis;
    /* Add ellipsis for overflowing text */
}