.matrix-rank {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.matrix-rank h2 {
    text-align: center;
}

.matrix-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.matrix-wrapper {
    display: inline-block;
}

.size-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.size-input {
    width: 50px;
    margin: 0 10px;
}

button {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

.result-container {
    margin-top: 20px;
    text-align: center;
}