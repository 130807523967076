.matrix-add-subtract {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.matrix-add-subtract h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.operation-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.operation-toggle label {
    margin: 0 10px;
    cursor: pointer;
}

.operation-toggle input[type="radio"] {
    margin-right: 5px;
}

.matrix-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.matrix-wrapper {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.operation {
    font-size: 24px;
    font-weight: bold;
}

.size-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.size-controls label {
    margin-right: 10px;
}

.size-input {
    width: 50px;
    padding: 5px;
    margin: 0 5px;
    text-align: center;
}

button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #45a049;
}

.result-container {
    margin-top: 20px;
}

.result-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 10px;
}

@media (max-width: 600px) {
    .matrix-container {
        flex-direction: column;
    }

    .operation {
        margin: 10px 0;
    }
}