.matrix-transpose {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.matrix-transpose h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.matrix-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.matrix-wrapper {
    display: inline-block;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.matrix-wrapper h3 {
    margin-top: 0;
    color: #444;
}

.size-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.size-input {
    width: 50px;
    margin: 0 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #45a049;
}

@media (max-width: 600px) {
    .matrix-container {
        flex-direction: column;
        align-items: center;
    }
}