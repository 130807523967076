.qr-factorization {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

h2 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.matrix-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.matrix-wrapper {
    display: inline-block;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    overflow-x: auto;
    /* Add horizontal scroll for smaller screens */
}

.matrix-cell {
    width: 50px;
    height: 30px;
    text-align: center;
    margin: 2px;
    border: 1px solid #ddd;
    border-radius: 3px;
}

.size-controls {
    text-align: center;
    margin-bottom: 20px;
}

.size-input {
    width: 50px;
    margin-left: 10px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 3px;
}

button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #45a049;
}

.result-container {
    margin-top: 20px;
    text-align: center;
}

.result-container h2 {
    margin-bottom: 10px;
}

.result-container h3 {
    margin-top: 15px;
    margin-bottom: 5px;
    color: #666;
}

.result-input {
    background-color: #e9e9e9;
    border: 1px solid #ccc;
}

@media (max-width: 600px) {
    .matrix-container {
        flex-direction: column;
        align-items: center;
    }

    .matrix-cell {
        width: 40px;
        height: 25px;
        font-size: 14px;
    }
}

.matrix-cell.result-input {
    width: 120px;
    /* Adjust this value as needed */
    font-size: 14px;
    /* Optionally reduce font size to fit more digits */
    font-family: monospace;
    /* Use a monospace font for better alignment */
}