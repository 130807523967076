.top-menu {
    background-color: #333;
    color: white;
    padding: 10px;
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -250px;
    transition: left 0.3s ease;
    overflow-y: auto;
}

.top-menu.open {
    left: 0;
}

.menu-container {
    position: relative;
}

.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: #34495e;
    display: flex;
    align-items: center;
    padding: 0 10px;
    z-index: 1000;
}

.menu-toggle {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: white;
    position: absolute;
    left: 10px;
}

.title-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.site-title {
    color: white;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
}

.sidebar {
    position: fixed;
    top: 50px;
    left: -250px;
    width: 250px;
    height: calc(100% - 50px);
    background-color: #34495e;
    transition: left 0.3s ease-in-out;
    z-index: 999;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar.open {
    left: 0;
}

.menu-categories {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
}

.menu-category {
    margin-bottom: 10px;
}

.category-toggle {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    text-align: left;
    width: 100%;
    padding: 10px 15px;
    transition: background-color 0.3s;
}

.category-toggle:hover {
    background-color: #2c3e50;
}

.submenu {
    list-style-type: none;
    padding-left: 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    background-color: #4a6b8a;
    /* Lighter blue for submenu */
}

.submenu.open {
    max-height: 500px;
}

.submenu li {
    margin: 5px 0;
}

.top-menu a {
    color: white;
    text-decoration: none;
    display: block;
    padding: 5px 10px;
    transition: background-color 0.3s;
}

.top-menu a:hover {
    background-color: #555;
}

/* Styles for the content area */
.content {
    margin-left: 0;
    transition: margin-left 0.3s ease;
    padding: 20px;
}

.content.menu-open {
    margin-left: 250px;
}

@media (max-width: 768px) {
    .top-menu {
        z-index: 1000;
    }

    .content.menu-open {
        margin-left: 0;
    }
}

.sidebar a {
    color: white;
    text-decoration: none;
    display: block;
    padding: 5px 10px;
    transition: background-color 0.3s;
}

.sidebar a:hover {
    background-color: #5c7d9e;
    /* Even lighter blue for hover state */
}

.overlay {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: calc(100% - 50px);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
}

@media (max-width: 768px) {
    .sidebar {
        width: 100%;
        left: -100%;
    }
}