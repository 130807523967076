.home {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Arial', sans-serif;
}

.hero {
    text-align: center;
    padding: 4rem 0;
    background-color: #f8f9fa;
    border-radius: 10px;
    margin-bottom: 3rem;
}

.hero h1 {
    font-size: 3.5rem;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.hero p {
    font-size: 1.2rem;
    color: #34495e;
}

.features,
.about {
    margin-bottom: 3rem;
}

h2 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 1.5rem;
    text-align: center;
}

.calculator-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
}

.calculator-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
}

.calculator-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.calculator-card h3 {
    font-size: 1.3rem;
    color: #2c3e50;
    margin-bottom: 0.5rem;
}

.calculator-card p {
    color: #34495e;
    margin-bottom: 1rem;
}

.btn {
    display: inline-block;
    background-color: #3498db;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #2980b9;
}

.about ul {
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
}

.about li {
    background-color: #ecf0f1;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    color: #34495e;
}

@media (max-width: 768px) {
    .hero h1 {
        font-size: 2.5rem;
    }

    .hero p {
        font-size: 1rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    .about ul {
        grid-template-columns: 1fr;
    }
}

.wwyb {
    text-align: center;
    margin-top: 40px;
    font-size: 0.8em;
    color: #888;
}