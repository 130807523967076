.lu-decomposition {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.matrix-input,
.result-matrix {
    margin-bottom: 20px;
}

.size-controls {
    margin-top: 10px;
}

.size-input {
    width: 50px;
    margin: 0 5px;
}

button {
    margin-bottom: 20px;
}

.results {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.result-matrix {
    width: 48%;
}

.readonly-matrix {
    display: inline-block;
}

.readonly-matrix .matrix-row {
    white-space: nowrap;
}

.readonly-matrix input {
    width: 50px;
    margin: 2px;
    text-align: right;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    color: #333;
    padding: 5px;
    font-size: 14px;
}

.matrix-cell.result-input {
    width: 120px;
    /* Adjust this value as needed */
    font-size: 14px;
    /* Optionally reduce font size to fit more digits */
}

@media (max-width: 768px) {
    .result-matrix {
        width: 100%;
    }
}